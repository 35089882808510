import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { DateTime } from "luxon"
import { Button, SizeType } from "./Buttons/Buttons"
import { Link } from "gatsby"
import CrossIcon from "../assets/svg/GFW_cross.svg"
interface ISchedule {
  data
}

const CountDownTimer: React.FC<ISchedule> = ({ data }) => {
  const date = DateTime.fromISO(data.attrs.date)
  const [now, setNow] = useState(DateTime.now())
  const [diff, setDiff] = useState(date.diff(now, ["days", "hours", "minutes", "seconds"]))
  useEffect(() => {
    const timerID = setInterval(() => setNow(DateTime.now()), 1000)
    return () => {
      clearInterval(timerID)
    }
  }, [])
  useEffect(() => {
    let mouted = true
    if (mouted) {
      setDiff(date.diff(now, ["days", "hours", "minutes", "seconds"]))
    }
    return () => {
      mouted = false
    }
  }, [now])
  const secs = diff.seconds

  function doubleZero(number: number) {
    if (number === 0) {
      return "00"
    }
    if (number > 0 && number < 10) {
      return "0" + number
    } else {
      return number
    }
  }
  return (
    <BlackBox>
      <ContentContainer>
        <CrossContainer>
          <StyledCrossIcon />
        </CrossContainer>
        <h2 style={{ color: "white", zIndex: 20 }}>
          GRADUATE FASHION WEEK 2021 <span style={{ color: "#E72569" }}>COUNTDOWN</span> IS ON
        </h2>
        <SmallText style={{ color: "white", zIndex: 20 }}>
          World's largest showcase of BA fashion <span style={{ color: "#E72569" }}>LIVE </span> |{" "}
          <span style={{ color: "#E72569" }}>12th - 18th June 2021 </span>
        </SmallText>

        <TimerContainer>
          <NumberContainer>
            <NumberCountHeading>{doubleZero(diff.days)}</NumberCountHeading>
            <NumberLabelHeading>DAYS</NumberLabelHeading>
          </NumberContainer>

          <NumberContainer>
            <NumberCountHeading>{doubleZero(diff.hours)}</NumberCountHeading>
            <NumberLabelHeading>HOURS</NumberLabelHeading>
          </NumberContainer>
          <ColonContainer>
            <NumberCountHeading>:</NumberCountHeading>
          </ColonContainer>
          <NumberContainer>
            <NumberCountHeading>{doubleZero(diff.minutes)}</NumberCountHeading>
            <NumberLabelHeading>MINUTES</NumberLabelHeading>
          </NumberContainer>
          <ColonContainer>
            <NumberCountHeading>:</NumberCountHeading>
          </ColonContainer>
          <NumberContainer>
            <NumberCountHeading>{doubleZero(Math.floor(secs))}</NumberCountHeading>
            <NumberLabelHeading>SECONDS</NumberLabelHeading>
          </NumberContainer>
        </TimerContainer>
        {/* <Button size={SizeType.large} to={"/register"} customwidth={"270px"} style={{ margin: " 0 auto 10px" }}>
          Register Now
        </Button>
        <Link to={"/events/login"}>Already Registered? Sign In</Link> */}
      </ContentContainer>
    </BlackBox>
  )
}

export default CountDownTimer

const BlackBox = styled.div`
  z-index: -10;
  width: 100%;
  text-align: center;
  background-color: black;
  padding-top: 110px;
  padding-bottom: 77px;
  margin-bottom: 10px;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 110px 20px 77px;
  }
`

const ContentContainer = styled.div`
  margin: auto;
  max-width: 600px;
  position: relative;

  /* border:1px solid white; */
`
const TimerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 40px;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 0 20px;
  }
`

const NumberContainer = styled.div`
  color: white;
  flex: 3;
`
const ColonContainer = styled.div`
  color: white;
  flex: 1;
`

const NumberCountHeading = styled.h2`
  font-size: 100px !important;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 65px;
  text-align: center;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 40px !important;
    line-height: 20px;
  }
`
const NumberLabelHeading = styled.h3`
  font-weight: 400;
  font-size: 25px;
  letter-spacing: 0;
  line-height: 40px;
  text-align: center;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 20px !important;
    line-height: 20px;
  }
`

const StyledCrossIcon = styled(CrossIcon)`
  height: 160px;
  width: 160px;
`

const CrossContainer = styled.div`
  margin: auto;
  width: 100%;
  margin-bottom: -140px;
  z-index: -1;
`

const SmallText = styled.p`
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;

  > span {
    font-size: 24px !important;
    font-weight: 600 !important;
    line-height: 30px !important;
  }
`

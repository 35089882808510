import { Container } from "@material-ui/core"
import React from "react"
import styled from "styled-components"
import CrossIcon from "../assets/svg/GFW_cross.svg"

interface ICrossHeading {
  data: any
}

const CrossHeading: React.FC<ICrossHeading> = ({ data }) => {
  const subtitle = data.attrs.subtitle
  const title = data.attrs.heading
  const text = data.attrs["body"]
  return (
    <CrossHeadingContainer>
      <HeadingContainer>
        <Cross />
        <CrossHeadingTitlesContainer>
          <CrossHeadingSubtitle>{subtitle}</CrossHeadingSubtitle>
          <CrossHeadingTitle>{title}</CrossHeadingTitle>
        </CrossHeadingTitlesContainer>
      </HeadingContainer>

      <CrossHeadingText>{text}</CrossHeadingText>
    </CrossHeadingContainer>
  )
}

export default CrossHeading

const CrossHeadingContainer = styled(Container)`
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 60px;
  position: relative;
  left: -80px;
  @media (max-width: 1400px) {
    left: 0px;
    align-items: left;
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 0px;
    left: 0px;
  }
`

const CrossHeadingTitlesContainer = styled.div`
  /* border: 1px solid red; */
  min-width: 280px;
  display: flex;
  flex-direction: column;
  margin-left: -80px;
  width: 100%;

  /* @media (min-width: 768px) {
    max-width: ${props => (props.titleWidth ? props.titleWidth : "320px")};
  } */
`

const CrossHeadingSubtitle = styled.h5`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 17px;
  margin-bottom: 0px;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 14px;
  }
`

const CrossHeadingTitle = styled.h2`
  font-size: 50px;
  font-weight: 600;
  @media (max-width: 768px) {
    font-size: 30px;
    line-height: 33px;
  }
`

const CrossHeadingText = styled.p`
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
  max-width: 500px;

  color: #4a4a4a;
  padding: 10px;
  @media (max-width: 768px) {
    font-size: 16px;

    line-height: 26px;
  }
`

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;

  /* @media (max-width: ${props => props.theme.breakpoints.md}) {
  
  } */
`

const Cross = styled(CrossIcon)`
  max-width: 160px;
  max-height: 160px;
  margin-left: 20px;
  @media (max-width: 768px) {
    width: 124px;
    height: 124px;
  }
`

import React, { useEffect, useState, useRef} from "react"
import styled from "styled-components"

interface LiveStreamProps {
  data: any
}

export const LiveStream: React.FC<LiveStreamProps> = ({ data }) => {
  const playerContainerRef = useRef(null);
  const [playerInitialized, setPlayerInitialized] = useState(false);

  useEffect(() => {
    if (!playerInitialized && playerContainerRef.current) {
      const vars = {
        clip_id: 'jdcthwgt7dc8',
        transparent: 'true',
        pause: '1',
        repeat: '',
        bg_color: '#FFFFFF',
        fs_mode: '2',
        no_controls: '',
        start_img: '0',
        start_volume: '34',
        close_button: '',
        brand_new_window: '1',
        auto_hide: '1',
        stretch_video: '',
        player_align: 'NONE',
        offset_x: '0',
        offset_y: '0',
        player_color_ratio: 0.6,
        skinAlpha: '50',
        colorBase: '#250864',
        colorIcon: '#FFFFFF',
        colorHighlight: '#7F54F8',
        direct: 'false',
        is_responsive: 'true',
        viewers_limit: 0,
        cc_position: 'bottom',
        cc_positionOffset: 70,
        cc_multiplier: 0.03,
        cc_textColor: '#FFFFFF',
        cc_textOutlineColor: '#FFFFFF',
        cc_bkgColor: '#000000',
        cc_bkgAlpha: 0.1,
        aspect_ratio: '16:9',
        play_button: '1',
        play_button_style: 'pulsing',
        sleek_player: '1',
        auto_play: '0',
        auto_play_type: 'unMute',
        floating_player: 'none',
      };
      // @ts-ignore
      const svp_player = new SVPDynamicPlayer(
        'svp_playerjdcthwgt7dc8',
        '',
        '100%',
        '100%',
        { use_div: 'svp_playerjdcthwgt7dc8', skin: '3' },
        vars
      );

      svp_player.execute();

      setPlayerInitialized(true);
    }
  }, [playerInitialized]);

  return (
    <StyledDiv style={{display: data.attrs.hidden ? "none":"grid"}}>
      {/* <div dangerouslySetInnerHTML={{ __html: data.attrs.video }}></div>
      <div dangerouslySetInnerHTML={{ __html: data.attrs.chat }} style={{ minHeight: 500, height: 500 }}></div> */}
      <div ref={playerContainerRef} id="svp_playerjdcthwgt7dc8" style={{width: '720px', height: '810px', position: 'relative'}} />
    </StyledDiv>
  )
}

const StyledDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 40px;
  
  margin-top: 60px;
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    margin-bottom: 0px;
  }
`

import { Container } from "@material-ui/core"
import React from "react"
import styled from "styled-components"

import { RichTextContainer } from "../LazyBlock/RichTextContainer"

interface ICrossHeading {
  data: any
}

const CrossHeadingRichGrid: React.FC<ICrossHeading> = ({ data }) => {
  const title = data.attrs["title"]
  const text1 = data.attrs["text-1"]
  const text2 = data.attrs["text-2"]
  const text3 = data.attrs["text-3"]
  const text4 = data.attrs["text-4"]
  const text5 = data.attrs["text-5"]
  const text6 = data.attrs["text-6"]

  return (
    <Container>
      <LocationContainer>
        <TitlesContainer>
          <Title>{title}</Title>
        </TitlesContainer>

        <BodyContainer>
          <RichTextContainer richText={text1} />
          <RichTextContainer richText={text2} />
          <RichTextContainer richText={text3} />
          <RichTextContainer richText={text4} />
          <RichTextContainer richText={text5} />
          <RichTextContainer richText={text6} />
        </BodyContainer>
      </LocationContainer>
    </Container>
  )
}

export default CrossHeadingRichGrid

const LocationContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  //align-items: center;

  margin-bottom: 50px;
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    left: 0px;
    align-items: left;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-top: 32px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 20px;
  }
`

const BodyContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 30px;
  width: 60%;
  padding-top: 10px;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
    padding-top: 0px;
  }
`

const TitlesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  width: 40%;
  margin-top: 0;
  padding: 0px 0px;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
    padding: 10px 0px;
  }
`
const Title = styled.h4`
  z-index: 1;
`

import styled from "styled-components"
import React from "react"

import { Container } from "@material-ui/core"
import { RichTextContainer } from "./RichTextContainer"
import Cross from "../../assets/svg/GFW_cross.svg"

interface FactBannerInterface {
  data: any
}

export const FactBanner = ({ data }: FactBannerInterface) => {
  const richText1 = data.attrs["rich-text-1"]
  const richText2 = data.attrs["rich-text-2"]
  const richText3 = data.attrs["rich-text-3"]
  return (
    <Container maxWidth={"lg"}>
      <InnerContainer maxWidth={"xl"}>
        <StatCard>
          <LineCrossContainer>
            <Line />
            <Cross width="30px" height="30px" /> <Line />
          </LineCrossContainer>
          <RichTextContainer richText={richText1} />
        </StatCard>

        <StatCard>
          <LineCrossContainer>
            <Line /> <Cross width="30px" height="30px" /> <Line />
          </LineCrossContainer>
          <RichTextContainer richText={richText2} />
        </StatCard>

        <StatCard>
          <LineCrossContainer>
            <Line />
            <Cross width="30px" height="30px" />
            <Line />
          </LineCrossContainer>
          <RichTextContainer richText={richText3} />
        </StatCard>
      </InnerContainer>
    </Container>
  )
}

const InnerContainer = styled(Container)`
  /* display: grid 
  width: 100%; */
  display: flex !important;
  justify-content: center;
  align-items: flex-start;
  background-color: #000000;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 65px;
  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
`

const StatCard = styled.div`
  display: flex;
  /* border: 1px solid red; */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  flex-wrap: break-word;
  /* margin: 40px; */
  overflow-x: hidden;
  margin: 0 2px;
  padding: 20px;
  > * {
    color: white;
    text-align: center;
    > * {
      color: white;
      text-align: center;
    }
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    max-width: 280px;
  }
`

const LineCrossContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  /* margin-bottom: 40px; */
`

const Line = styled.div`
  content: "";

  width: 100%;
  flex: 1;
  height: 1px;
  border: none;
  background-color: ${props => props.theme.colors.turquoise};
  @media (max-width: 1156px) {
    width: 100px;
  }
  @media (max-width: 1024px) {
    width: 140px;
  }
`

const DividerContainer = styled.div``

import React, { useState } from "react"
import styled, { useTheme } from "styled-components"
import { ButtonSecondary, SizeType } from "../Buttons/Buttons"
import { GalleryCaroselPopup } from "./GalleryCaroselPopup"
import { ThumbnailGalleryItem } from "./ThumbnailGalleryItem"

interface ThumbnailGalleryI {
  blockData: any
}

export const ThumbnailGallery: React.FC<ThumbnailGalleryI> = ({ blockData }) => {
  const theme = useTheme()
  const rawGallery = decodeURIComponent(blockData.attrs.gallery)
  const jsonGallery = JSON.parse(rawGallery)
  const [numberShowing, setNumberShowing] = useState(6)
  const HandleShowAll = () => {
    setNumberShowing(jsonGallery.length)
  }
  const HandleCollapse = () => {
    setNumberShowing(6)
  }

  const [modalOpen, setModalOpen] = useState(false)

  const [cardNumber, setCardNumber] = React.useState(0)

  return (
    <MainContainer>
      <GalleryCaroselPopup
        imageData={jsonGallery}
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
        cardNumber={cardNumber}
        setCardNumber={setCardNumber}
      />

      <GridContainer>
        {jsonGallery.map((v, i) => {
          if (i < numberShowing) {
            return (
              <ThumbnailGalleryItem
                imageData={v}
                index={i}
                key={"ThumbnailGalleryItem-" + i}
                setModalOpen={setModalOpen}
                setCardNumber={setCardNumber}
                // setSwiper={setSwiper}
              />
            )
          } else {
            null
          }
        })}
      </GridContainer>
      {/* <p>Gallery Component - Comming soon</p> */}
      <div style={{ maxWidth: 340, marginTop: 30 }}>
        {numberShowing > 6 ? (
          <div onClick={HandleCollapse}>
            <ButtonSecondary
              // size={SizeType.large}

              size={SizeType.medium}
            >
              COLLAPSE
            </ButtonSecondary>
          </div>
        ) : (
          <div onClick={HandleShowAll}>
            <ButtonSecondary
              // size={SizeType.large}

              size={SizeType.medium}
            >
              SHOW ALL
            </ButtonSecondary>
          </div>
        )}
      </div>
    </MainContainer>
  )
}

const MainContainer = styled.div`
  padding-top: 50px;
  padding-bottom: 20px;
`
const GridContainer = styled.div`
  /* padding-top: 30px; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  column-gap: 16px;
  row-gap: 16px;
  /* height: 500px; */
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    grid-template-columns: 1fr 1fr;
  }
`
const GridItem = styled.div`
  background-color: #d4d4d4;
  width: 100%;
  content: "";
  border-radius: 8px;
  height: 100%;
`

const ButtonContainer = styled.div`
  margin: 0 auto;
`

import React from "react"
import { RichTextContainer } from "./RichTextContainer"

interface UniversityCourseBlockI {
  data:
    | {
        name: string
        description: string
      }
    | any
}

export const UniversityGraduatesBlock: React.FC<UniversityCourseBlockI> = ({ data }) => {
  const name = data.attrs["heading"]
  const description = data.attrs["graduates-intro"]

  return (
    <div style={{ paddingTop: 65 }}>
      <h5 style={{ fontSize: "1.6rem", marginBottom: "1rem" }}>{name}</h5>
      <div style={{ maxWidth: 800, marginTop: 30 }}>
        <RichTextContainer richText={description} />
      </div>
    </div>
  )
}

import React, { useLayoutEffect, useState, FunctionComponent } from "react"
import { graphql, Link, StaticQuery } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import { Swiper, SwiperSlide } from "swiper/react"
import { Container } from "@material-ui/core"
import CrossIcon from "../../assets/svg/GFW_cross.svg"



interface IImage {
  sourceUrl: string
}

interface IKeynoteprops {
  speakers: any[]
}
const FeaturedImageScroll: FunctionComponent= () => {
  const [slides, setSlides] = useState(3)
  const [currentSlide, setCurrentSlide] = useState(0)

  useLayoutEffect(() => {
    function updateSize() {
      if (typeof window !== "undefined") {
        if (window.innerWidth <= 767) {
          setSlides(1.4)
        } else {
          setSlides(3)
        }
      }
    }
    window.addEventListener("resize", updateSize)
    updateSize()
    return () => {
      window.removeEventListener("resize", updateSize)
    }
  }, [])

  const handleChangeSlide = (event, value) => {
    //setCurrentSlide(event.activeIndex)
    setCurrentSlide(event.realIndex)
  }

  return (
    <StaticQuery
    query={graphql`
      query {
        allWpKeynoteSpeaker {
          edges {
            node {
              keynoteSpeakers {
                description
                name
                role
                date
                featuredImage {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 400) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    //
    render={data => {
      
        let keynoteSpeakers = []
        try{
           keynoteSpeakers = data.allWpKeynoteSpeaker.edges.map(v => v.node)
        }catch(e){
          // console.log(e)
        }
      return(
    <Container>
      <TopTextContainer>
        <SmallHeading>GUEST SPEAKERS</SmallHeading>
        <h2>KEYNOTE SPEAKERS</h2>
      </TopTextContainer>
      <Container2>
        <Swiper
          className="swiper-container"
          slidesPerView={slides}
          navigation
          scrollbar={{ hide: false }}
          loop={true}
          effect={"coverflow"}
          coverflowEffect={{
            scale: 0.8,
            rotate: 2,
            depth: 50,
            modifier: 1,
            slideShadows: true,
          }}
          spaceBetween={-20}
          centeredSlides={true}
          onSlideChange={handleChangeSlide}
        >
          {keynoteSpeakers.map((item, i) => {
            return (
              item && (
                <SwiperSlide key={"keynote-speaker-" + i} className="carousel-drag-slide">
                  <Img
                    fluid={item.keynoteSpeakers.featuredImage.localFile.childImageSharp.fluid}
                    style={{ height: "auto", width: "100%" }}
                  />
                  <>
                    <CrossIconContainer>
                      <Cross />
                    </CrossIconContainer>
                    <TextContainer center={i == currentSlide}>
                      <Tag>{item.keynoteSpeakers.role}</Tag>
                      <Name>{item.keynoteSpeakers.name}</Name>
                      <SpeakerInfo>{item.keynoteSpeakers.description}.</SpeakerInfo>
                      <SpeakerInfo>{item.keynoteSpeakers.date}.</SpeakerInfo>
                      <Link to="/events/">Find out more</Link>
                    </TextContainer>
                  </>
                </SwiperSlide>
              )
            )
          })}
        </Swiper>
      </Container2>
    </Container>
    )
  }}
/>  )
}

export default FeaturedImageScroll

const HeightPlaceholder = styled.div`
  min-height: 300px;
`
const Container2 = styled.section`
  width: 100%;
  margin: 0px auto;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
  }
  .swiper-container {
    padding: 0 0 100px; //so the scroll bar is visible bellow the slides
    transition: transform 0.8s ease;
    width: 100%;
    height: auto;
    transition: transform 0.4s ease;
    @media (min-width: 1024px) {
      width: 100%;
    }
  }
  .carousel-drag-slide {
    margin: auto; //for horizontal centering
    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      margin: 0 auto;
    }
  }

  .swiper-scrollbar {
    position: absolute;
    height: 1px;
    //width: calc(100vw - 140px);
    width: 30%;
    background: ${props => props.theme.colors.turquoise};
    left: 40%;
    bottom: 68px;
    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      width: 60%;
      left: 30%;
    }
  }
  .swiper-scrollbar-drag {
    height: 3px;
    // width: 50px;
    // position: absolute;
    top: -1px;
    background: ${props => props.theme.colors.turquoise};
  }

  .swiper-button-next {
    //margin-left: 18px;
    left: 35%;
    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      left: 15%;
    }
  }

  .swiper-button-prev {
    transform: rotate(-180deg);
    left: 30%;
    @media (max-width: ${props => props.theme.breakpoints.sm}) {
      left: 0%;
    }
  }
  .swiper-button-next::after,
  .swiper-button-prev::after {
    opacity: 0;
  }
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: auto;
    bottom: 60px;
    width: 4.9rem;
    height: 1rem;
    margin: 0;
  }

  .swiper-button-next,
  .swiper-button-prev {
    background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ny44NCAxMS4yNSI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiMwZGIwZTc7ZmlsbC1ydWxlOmV2ZW5vZGQ7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGlkPSJDb21iaW5lZC1TaGFwZSIgY2xhc3M9ImNscy0xIiBkPSJNNDUuMzgsNiw0MS4xNiwxLjg0bDEtMSw1LjcsNS42Mi01LjcsNS42Mi0xLTFMNDUuMzgsN0gwVjZaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC0wLjg4KSIvPjwvc3ZnPg==);
    background-repeat: no-repeat;
    /* background-color: ${props => props.theme.colors.turquoise}; */
    background-size: 100% auto;
    background-position: center;
    width: 45px;
    height: 21px;
  }
  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    /* background: url(../assets/svg/long-arrow-right.svg); */
    background-repeat: no-repeat;
    background-size: 100% auto;

    background-position: center;
  }
  .swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
  }
`

interface TextContainerInterface {
  center: boolean
}
const TextContainer = styled.div<TextContainerInterface>`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0 20px;
  align-items: center;
  margin: 0px auto 0;

  > * {
    opacity: ${props => (props.center ? 1 : 0)};
    transition: opacity 0.5s;
  }
`

const Name = styled.h4`
  color: black;
  font-size: 26px;
`
const SpeakerInfo = styled.p`
  color: black;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 2px;
  text-align: center;
  margin-bottom: 15px;
`
const TopTextContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  align-items: center;
  margin: 80px auto 0;
`
const Tag = styled.b`
  text-align: center;
  text-transform: uppercase;
  background-color: ${props => props.theme.colors.turquoise};
  color: white;
  font-size: 0.7rem;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 1px;
  margin: 10px 5px 5px 0;
  width: auto;
  padding: 0px 8px 0px;
  border-radius: 10px;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 10px;
  }
`

const CrossIconContainer = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
`
const Cross = styled(CrossIcon)`
  height: 80px;
  width: 80px;
  position: relative;
  top: -20px;
  margin: 0 auto;
`
const SmallHeading = styled.h6`
  font-size: 14px;
  color: black;
  margin-bottom: 0px;
  font-weight: medium;
`

import { Container } from "@material-ui/core"
import React from "react"
import styled from "styled-components"
import { ButtonSecondary } from "../Buttons/Buttons"
import { RichTextContainer } from "./RichTextContainer"
import CrossIcon from "../../assets/svg/GFW_cross.svg"
import { LazyLoadImage } from "react-lazy-load-image-component"

interface ITextWithSplitImageOverlap {
  data: any
}

export const TextWithSplitImageOverlap: React.FC<ITextWithSplitImageOverlap> = ({ data }) => {
  const subtitle = data.attrs.subtitle
  const body = data.attrs.body
  const image = data.attrs.image
  const catLabel = data.attrs["call-to-action-label"]
  const catLink = data.attrs["call-to-action-link"]
  // @todo

  //   const mainimage = useStaticQuery(graphql`
  //   query MyQuery {
  //     allWpPartner {
  //       edges {
  //         node {
  //           PartnerInfo {
  //             partnershipLogo {
  //               localFile {
  //                 childImageSharp {
  //                   fluid(maxWidth: 80) {
  //                     ...GatsbyImageSharpFluid
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  // `)
let jsonImage = {alt: null, url:null};
  try{
  const rawImage = decodeURIComponent(image)
  jsonImage = JSON.parse(rawImage)
  }catch(e){
    // console.log(e)
  }
  
  return (
    <Container>
      <AboutUsWrapper>
        <AboutUsImgContainer>
          <LazyLoadImage
            alt={jsonImage.alt}
            width={"100%"}
            effect="blur"
            src={jsonImage.url} // use normal <img> attributes as props
          />
          <Cross />
        </AboutUsImgContainer>
        <AboutUsTextBox>
          <Subtitle>{subtitle}</Subtitle>
          <RichTextContainer richText={body} />
          {catLink && (
            <ButtonSecondaryAboutUs customwidth="300px" to={catLink}>
              {catLabel}
            </ButtonSecondaryAboutUs>
          )}
        </AboutUsTextBox>
        <Background />
      </AboutUsWrapper>
    </Container>
  )
}

const AboutUsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 0.75fr 2fr 2fr 0.25fr;
  width: 100%;

  margin: 0 auto 60px;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    display: flex;
    flex-direction: column;
  }
`
const AboutUsImgContainer = styled.div`
  z-index: 1;
  grid-area: 2/1/4/3;
  display: flex;

  height: wrap;
  flex-direction: column;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUAgMAAADw5/WeAAAACVBMVEX6+vr4+Pjv7++npPQmAAAAKElEQVQI12NgCGBgZWBgEGBgZACzYWTo0tCoUAiJIs7gwMCCi02CXgD5agzBt66q6wAAAABJRU5ErkJggg==");
  }
`
const Background = styled.div`
  grid-area: 1/2/5/5;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUAgMAAADw5/WeAAAACVBMVEX6+vr4+Pjv7++npPQmAAAAKElEQVQI12NgCGBgZWBgEGBgZACzYWTo0tCoUAiJIs7gwMCCi02CXgD5agzBt66q6wAAAABJRU5ErkJggg==");
  z-index: 0;
`
const IMG = styled.img`
  object-fit: contain;
  width: 100%;
`

const AboutUsTextBox = styled.div`
  grid-area: 2/3/4/5;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-items: flex-start;
  padding: 0 65px;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 20px;
    height: auto;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUAgMAAADw5/WeAAAACVBMVEX6+vr4+Pjv7++npPQmAAAAKElEQVQI12NgCGBgZWBgEGBgZACzYWTo0tCoUAiJIs7gwMCCi02CXgD5agzBt66q6wAAAABJRU5ErkJggg==");
  }
`

const Cross = styled(CrossIcon)`
  /* max-width: 230px;
  max-height: 230px; */
  padding: 60px;
  position: relative;
  top: -140px;
  margin-bottom: -140px;
  z-index: 1;
`

const Subtitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 30px;
  text-transform: uppercase;
  margin-bottom: 15px;
  text-align: left;
`

const ButtonSecondaryAboutUs = styled(ButtonSecondary)`
  margin: 0px 0;
  @media (max-width: 768px) {
    width: 100%;
  }
`

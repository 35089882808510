import { Card, IconButton } from "@material-ui/core"
import React from "react"
import CloseIcon from "@material-ui/icons/Close"
import styled from "styled-components"
import "swiper/swiper-bundle.css"
import ArrowLeft from "../../assets/svg/long-arrow-left.svg"
import ArrowRight from "../../assets/svg/long-arrow-right.svg"
interface GalleryCaroselPopupI {
  imageData: any
  modalOpen: boolean
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setCardNumber: any
  cardNumber: number
}

export const GalleryCaroselPopup: React.FC<GalleryCaroselPopupI> = ({
  imageData,
  modalOpen,
  setModalOpen,
  setCardNumber,
  cardNumber,
}) => {
  const handleBack = () => {
    if (cardNumber !== 0) {
      setCardNumber(cardNumber - 1)
    }
  }
  const handleForward = () => {
    if (cardNumber !== imageData.length - 1) {
      setCardNumber(cardNumber + 1)
    }
  }
  let dotWidth
  let dotPosition
try{
   dotWidth = `${(1 / imageData.length) * 100}%`
   dotPosition = `${(1 / imageData.length) * 100 * cardNumber}%`

   return (
    <div>
      <MyModal
        open={modalOpen}
        onClick={() => {
          setModalOpen(false)
        }}
      >
        <ModalContent>
          <StyledCard
            onClick={e => {
              e.stopPropagation()
            }}
          >
            <IconButtonContainer>
              <IconButton
                onClick={() => {
                  setModalOpen(false)
                }}
              >
                <CloseIcon />
              </IconButton>
            </IconButtonContainer>
            <ImageContainer url={imageData[cardNumber].url}></ImageContainer>
            <ArrowContainer>
              <SingleArrowContainer>
                <ArrowLeft onClick={handleBack} style={{ maxWidth: 46, height: 15, marginRight: 10 }} />
              </SingleArrowContainer>
              <SingleArrowContainer>
                <ArrowRight style={{ maxWidth: 46, height: 15 }} onClick={handleForward} />
              </SingleArrowContainer>
              <BarContainer>
                <Bar>
                  <BarDot barWidth={dotWidth} barPosition={dotPosition} />
                </Bar>
              </BarContainer>
            </ArrowContainer>
            <CaptionContainer>
              <p>{imageData[cardNumber].caption}</p>
            </CaptionContainer>
            <CounterContainer>
              <span>{cardNumber + 1 + "/" + imageData.length}</span>
            </CounterContainer>
          </StyledCard>
        </ModalContent>
      </MyModal>
    </div>
  )
}catch(e){
  // console.log(e)
  return <div className={"Nodata"}></div>
}


 
}

const CaptionContainer = styled.div`
  /* border: 1px solid red; */
  margin-left: 60px;
  margin-right: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  > p {
    /* border: 1px solid red; */
    color: black;
    text-align: center;
  }
`

const SingleArrowContainer = styled.div`
  max-width: 46px;
  height: 15px;
  margin-right: 20px;
`

const IconButtonContainer = styled.div`
  /* border: 1px solid black; */
  display: flex;
  justify-content: flex-end;
`
const ArrowContainer = styled.div`
  /* border: 1px solid black; */
  display: flex;
  width: 100%;
  padding-left: 55px;
  padding-right: 55px;
  /* border: 1px solid red; */
`
const BarContainer = styled.div`
  /* border: 1px solid black; */
  content: "";
  display: flex;
  flex: 1;
  width: 100%;
  /* border: 1px solid red; */
  align-items: center;
`
const Bar = styled.div`
  /* border: 1px solid black; */
  min-height: 1px;
  max-height: 1px;
  width: 100%;
  background-color: #cbcbcb;
`

interface BarDotI {
  barWidth: string
  barPosition: string
}

const BarDot = styled.div<BarDotI>`
  background-color: #0db0e7;
  min-height: 3px;
  max-height: 3px;
  top: -1px;
  position: relative;
  left: ${props => props.barPosition};
  min-width: ${props => props.barWidth};
  max-width: ${props => props.barWidth};
`
// const ImageContainer = styled.div``
const CounterContainer = styled.div`
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  /* border: 1px solid red; */
  > span {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 26px;
    text-align: center;
  }
`
interface ImageContianerI {
  url: string
}
const ImageContainer = styled.div<ImageContianerI>`
  height: 500px;
  width: 460px;
  display: flex;
  background-image: url(${props => props.url});
  background-size: cover;
  background-position: center;
  /* border: 1px solid red; */
  margin: 0px auto;
  overflow: hidden;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    height: 375px;
    width: 300px;
  }
`
const StyledImg = styled.img`
  flex: 1;
`

interface ModalI {
  open: boolean
}

const MyModal = styled.div<ModalI>`
  display: ${props => (props.open ? "block" : "none")}; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 10; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  /* display: flex; */
  justify-content: center;
  align-items: center;
`

const ModalContent = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 3;
`

const StyledCard = styled(Card)`
  max-width: 520px;
  max-height: 710px;
  width: 520px;
  height: 710px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 2 !important; /* Sit on top */
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    height: 610px;
    width: 340px;
    max-width: 340px;
    max-height: 610px;
  }
`

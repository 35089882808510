import React from "react"
import styled from "styled-components"

interface IImageProps {
  data: any
}
const PartnerLogo: React.FC<IImageProps> = ({ data }) => {
  const image = data.attrs.image
  let jsonImage

  try {
    const rawGallery = decodeURIComponent(image)
    const jsonImageRaw = JSON.parse(rawGallery)
    jsonImage = jsonImageRaw.url
  } catch (e) {
    console.log(e)
  }
  if(jsonImage===undefined){
  try {
    jsonImage = image.sourceUrl
  } catch (e) {
    // console.log(e)
  }
}
  return (
    <Container>
      <IMG src={jsonImage}></IMG>
    </Container>
  )
}

export default PartnerLogo

const Container = styled.div`
  display: flex;

  justify-content: center;
  /* align-items: center;
  vertical-align: center; */
  margin: 50px auto 80px;
  height: 175px;
  width: 350px;
  /* border: 4px solid black; */
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 280px;
  }
`
const IMG = styled.img`
  object-fit: fill;
  height: 100%;
`

import React from "react"
import styled from "styled-components"
import { RichTextContainer } from "./RichTextContainer"
import { Container } from "@material-ui/core"

interface IIndentedTextWithLargeImage {
  data: any
}

export const IndentedTextWithLargeImage: React.FC<IIndentedTextWithLargeImage> = ({ data }) => {
  const subtitle = data.attrs.subtitle
  const body = data.attrs.body
  const imageOnLeft = data.attrs["image-left-aligned"]

  const image = data.attrs.image
  let jsonImage

  try {
    const rawGallery = decodeURIComponent(image)
    const jsonImageRaw = JSON.parse(rawGallery)
    jsonImage = jsonImageRaw.url
  } catch (e) {
    // console.log(e)
  }
  if(jsonImage===undefined){
  try {
    jsonImage = image.sourceUrl
  } catch (e) {
    // console.log(e)
  }}

  return (
    <Container>
      <AboutUsWrapper imageLeft={imageOnLeft}>
        <AboutUsTextBox>
          <Subtitle>{subtitle}</Subtitle>
          <RichTextContainer richText={body} />
        </AboutUsTextBox>
        <AboutUsImgContainer>
          {/* <div
            style={{
              height: "100%",
              width: "auto",
              backgroundImage: `url(${image})`,
              objectFit: "cover",
            }}
          ></div> */}
          <IMG src={jsonImage}></IMG>
        </AboutUsImgContainer>
      </AboutUsWrapper>
    </Container>
  )
}

interface IAlignmentProps {
  imageLeft: boolean
}
const AboutUsWrapper = styled.div<IAlignmentProps>`
  width: 100%;
  /* height: 600px; */
  margin: 30px auto 40px;
  display: flex;
  flex-direction: ${props => (props.imageLeft ? "row-reverse" : "row")};
  //justify-content: center;
  align-items: flex-start;
  padding-top: 60px;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: ${props => (props.imageLeft ? "column-reverse" : "column")};
    align-items: flex-end;
    height: auto;
    width: 100%;
    padding-top: 0px;
    margin: 30px auto 10px;
  }
`
const AboutUsImgContainer = styled.div`
  width: 50%;
  overflow: hidden;
  height: 500px;
  padding: 120px auto;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 auto;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: column;
    width: 100%;
    height: 485px;
    margin: auto;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
    width: 100%;
    height: 285px;
    margin: auto;
  }
`
const IMG = styled.img`
  object-fit: contain;
`
const AboutUsTextBox = styled.div`
  width: 50%;
  min-height: wrap;
  display: flex;

  flex-direction: column;
  justify-content: center;
  z-index: 2;
  padding: 0 30px;

  margin: 0;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
    height: auto;
    padding: 30px 20px 20px 0px;
    margin: 0;
    align-items: flex-start;
    justify-content: space-between;
  }
`

const Subtitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 30px;
  text-transform: uppercase;
  margin-bottom: 15px;
  text-align: left;
  color: black;
`

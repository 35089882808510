/* eslint-disable react/display-name */
import { parse } from "@wordpress/block-serialization-default-parser"
import React from "react"
import CrossHeading from "../components/CrossHeading"
import CrossHeadingTwo from "../components/CrossHeadingTwo"
import CrossHeadingRichGrid from "../components/ContactUs/CrossHeadingRIchGrid"
import { FactBanner } from "../components/LazyBlock/FactBanner"
import ImageWithSplitTextOverlap from "../components/LazyBlock/ImageWithSplitTextOverlap"
import { IndentedTextWithLargeImage } from "../components/LazyBlock/IndentedTextWithLargeImage"
import { PartnershipGallery } from "../components/LazyBlock/Partnershipgallery"
import { RichTextContainer } from "../components/LazyBlock/RichTextContainer"
import { SmallTextWithGallery } from "../components/LazyBlock/SmallTextWithGallery"
import { SplitImageVideoWithText } from "../components/LazyBlock/SplitImageVideoWithText"
import { TextWithSplitImageOverlap } from "../components/LazyBlock/TextWithSplitImageOverlap"
import { ThumbnailGallery } from "../components/LazyBlock/ThumbnailGallery"
import { UniversityCourseBlock } from "../components/LazyBlock/UniversityCourseBlock"
import PartnerLogo from "../components/PartnerLogo"
import HowToGetThere from "../components/ContactUs/HowToGetThere"
import Locations from "../components/ContactUs/Locations"
import MapEvent from "../components/ContactUs/MapEvent"
import { VideoLazyBlock } from "../components/LazyBlock/VideoLazyBlock"
import ScheduleDownload from "../components/ScheduleDownload"
import CountDownTimer from "../components/CountDownTimer"
import { HomePageSchedule } from "../components/LazyBlock/HomePageSchedule"
import { UniversityGraduatesBlock } from "../components/LazyBlock/UniversityGraduatesBlock"
import { LiveStream } from "../components/LazyBlock/LiveStream"
import { v4 as uuidv4 } from 'uuid';
import FeaturedPortfoliosCarouselSingle from "../components/PortfoliosTemplate/FeaturedPortfoliosCarouselSingle"
import KeynoteSpeakers from "../components/PortfoliosTemplate/KeynoteSpeakers"
interface LazyBlockFactoryI {
  lazyBlocks: any
  pageTitle: string
}

export function LazyBlockFactory<LazyBlockFactoryI>(lazyBlocks, pageTitle) {
  const lazyBlocksData = parse(lazyBlocks)
  const stuff = lazyBlocksData.map(v => {
    try {
      return LazyBlock[v.blockName](v)
    } catch (e) {
      console.error(e)
      return (
        <div className={"max-w-md mx-auto p-5"} key={uuidv4()}>
          <p className={"text-red-600"}>Lazy block error on: {v.blockName} </p>
        </div>
      )
    }
  })
  return stuff
}
const LazyBlock = {
  "lazyblock/split-image-video-with-text": block => <SplitImageVideoWithText data={block} />,
  "lazyblock/indented-text-with-large-image": block => <IndentedTextWithLargeImage data={block} />,
  "lazyblock/partner-logo": block => <PartnerLogo data={block} />,
  "lazyblock/home-page-schedule": block => <HomePageSchedule data={block} />,
  "lazyblock/large-cross-with-intro-paragraph": block => <CrossHeading data={block} />,
  "lazyblock/large-cross-with-two-intro-paragraph": block => <CrossHeadingTwo data={block} />,
  "lazyblock/contact-us-links": block => <CrossHeadingRichGrid data={block} />,
  "lazyblock/text-with-split-image-overlap": block => <TextWithSplitImageOverlap data={block} />,
  "lazyblock/image-with-split-text-overlap": block => <ImageWithSplitTextOverlap data={block} />,
  "core/embed": block => <VideoLazyBlock data={block} />,
  "lazyblock/facts-banner": block => <FactBanner data={block} />,
  "lazyblock/small-text-with-gallery": block => <SmallTextWithGallery data={block} />,
  "lazyblock/partnership-gallery": block => <PartnershipGallery data={block} />,
  "lazyblock/university-course-block": block => <UniversityCourseBlock data={block} />,
  "lazyblock/university-graduates-block": block => <UniversityGraduatesBlock data={block} />,
  "lazyblock/travel-links": block => <HowToGetThere data={block} />,
  "lazyblock/locations": block => <Locations data={block} />,
  "lazyblock/gfw-address": block => <MapEvent data={block} />,
  "lazyblock/thumbnail-gallery": block => <ThumbnailGallery blockData={block} />,
  "lazyblock/schedule-download": block => <ScheduleDownload data={block} />,
  "lazyblock/count-down-timer": block => <CountDownTimer data={block} />,
  "lazyblock/live-stream": block => <LiveStream data={block} />,
  "core/heading": block => <RichTextContainer richText={block.innerHTML} />,
  "core/separator": block => <RichTextContainer richText={block.innerHTML} />,
  "core/paragraph": block => <RichTextContainer richText={block.innerHTML} />,
  "core/list": block => <RichTextContainer richText={block.innerHTML} />,
  "lazyblock/event-schedule-unit": block => <></>,
  "lazyblock/featured-portfolio-carousel": block => <FeaturedPortfoliosCarouselSingle data = {block}/>,
  "lazyblock/key-note-speakers-carousel": block => <KeynoteSpeakers/>,
  null: () => <RichTextContainer richText={"<br/>"} />
}

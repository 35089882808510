import { Container } from "@material-ui/core"
import { graphql, StaticQuery } from "gatsby"
import React from "react"
import Carousel from "../Carousel"


export const FeaturedPortfoliosCarouselSingle = ({  data }) => {

  const displayPortfolios = []
  let heading ="Accessory Design"
  try{
    heading = data.attrs.specialism
  }catch(e){
    // console.log(e)
  }

  const colours = ["#0DB0E7", "#E72569","#47BFAF","#000000"]

  return (


    <StaticQuery
    query={graphql`
      query {
   
        allWpPortfolio {
      edges {
        node {
          id
          title
          slug
          student_portfolio {
            aboutMeHeading
          }
          student_profile {
            studentName
            specialism
            searchImage {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
      }
    `}
    render={data => {

      let portfolios = []

      try{
        portfolios = data.allWpPortfolio.edges.map(v => v.node)
      }catch(e){
        // console.log(e)
      }

      portfolios.map((item, index) => {
        const specs = item.student_profile.specialism !== null ? item.student_profile.specialism : []
        try{
          if (specs.includes(heading)) {

          displayPortfolios.push(item)
        }
      }catch(e){
        // console.log(e)
      }
      })
      return    ( <Container>
      <Carousel
        color={colours[Math.floor(Math.random() * colours.length)]}
        id={heading}
        data={displayPortfolios.sort(() => Math.random() - 0.5)}
      ></Carousel>

    </Container>)
    }}
  />


  )
}

export default FeaturedPortfoliosCarouselSingle

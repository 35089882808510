import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { Card } from "@material-ui/core"

interface IportfolioCard {
  img?: any
  name: string
  slug: string
  desc: string
  tags?: string[]
  color?: string
}

const PortfolioCarouselCard: React.FC<IportfolioCard> = ({ img, name, desc, slug, tags, color }: IportfolioCard) => {
  const [shadow, setShadow] = useState({ front: 0, back: 0 })
  const onMouseOver = () => setShadow({ front: 15, back: 5 })
  const onMouseOut = () => setShadow({ front: 0, back: 0 })
  return (
    <PortfolioCardContainer to={"/search-portfolios/" + slug}>
      <StyledCard
        // elevation={shadow}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
      >
        <ImgContainer className="gatsby-image-wrapper" color={color} elevation={shadow.back}>
          <Img
            fluid={img}
            style={{ height: "auto", width: "100%" }}
            imgStyle={{ objectFit: "cover" }}
            //  placeholderStyle={{ opacity: 0 }}
            loading={"eager"}
          />
        </ImgContainer>
        <PortfolioInfoBox elevation={shadow.front}>
          {tags && (
            <TagContainer>
              {tags.slice(0, 2).map((item, i) => {
                return (
                  <Tag key={name + "-tag-" + i} color={color}>
                    {item}{" "}
                  </Tag>
                )
              })}
            </TagContainer>
          )}
          <PortfolioName>{name}</PortfolioName>
          <PortfolioDesc>{desc}</PortfolioDesc>
        </PortfolioInfoBox>
      </StyledCard>
    </PortfolioCardContainer>
  )
}

export default PortfolioCarouselCard

const StyledCard = styled.div`
  border-radius: 0px !important;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  /* border: 1px solid red; */
  height: 100%;
`

const PortfolioCardContainer = styled(Link)`
  margin: 10px 5px;
  background-color: #ffffff;
  &:hover {
    text-decoration: none;
  }
  max-width: 315px;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    max-width: 100%;
    margin: 10px 5px;
  }
`
const ImgContainer = styled(Card)<Icolor>`
  //width: 315px;
  width: 100%;
  height: 325px;
  max-height: 325px;
  border-top: 4px solid ${props => (props.color ? props.color : "black")};
  border-radius: 0px !important;
  & > .gatsby-image-wrapper {
    z-index: 1;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    max-width: 100%;
    height: auto;
    margin: 0 0px;
  }
`
const PortfolioInfoBox = styled(Card)`
  padding: 21px 27px 26px;
  background: white;
  border-radius: 0px !important;
  height: 185px;
  width: 295px;

  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  /* box-shadow: 0 0 5px 0 rgba(123, 123, 123, 0.2); */
  /* transition: transform 1s ease; */
  margin-top: -100px;
  margin-right: 0;
  z-index: 9;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 12px 13px;
    margin: -40px 0px 0;
    width: 95%;
  }
`

const PortfolioName = styled.p`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 26px;
  height: 38px;
  margin-bottom: 18px;
  color: black;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 16px;

    line-height: 21px;
  }
`

const PortfolioDesc = styled.p`
  color: #4a4a4a !important;
  font-size: 18px;
  line-height: 28px;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 14px;

    line-height: 24px;
  }
`
const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding-right: 0%;
  }
`
interface IColor {
  color: string
}
const Tag = styled.b<IColor>`
  text-align: center;
  text-transform: uppercase;
  background-color: ${props => (props.color ? props.color : "black")};
  color: white;
  font-size: 0.7rem;
  line-height: 0.7rem;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 0 5px 5px 0;
  /* height: 20px; */
  width: auto;
  padding: 4px 8px;
  border-radius: 10px;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    font-size: 10px;
  }
`

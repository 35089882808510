import React from "react"
import styled from "styled-components"
import { Button, SizeType } from "./Buttons/Buttons"

interface ISchedule {
  data
}

const ScheduleDownload: React.FC<ISchedule> = ({ data }) => {
  const file = JSON.parse(decodeURI(data.attrs.file))

  return (
    <>
      <CrossHeadingContainer>
        {/* Note this is named poorly but basically this all just acts as a placeholder */}
        <HeadingContainer>
          <Cross />
          <CrossHeadingTitlesContainer>
            <CrossHeadingTitle></CrossHeadingTitle>
          </CrossHeadingTitlesContainer>
        </HeadingContainer>
        <ButtonWrapper>
          <a href={file.url} download style={{ textDecoration: "none" }}>
            <div style={{ paddingTop: 20 }}>
              <Button customwidth={"300px"} size={SizeType.large}>
                Download Full Schedule
              </Button>
            </div>
          </a>
        </ButtonWrapper>
        {/* </CrossHeadingText> */}
      </CrossHeadingContainer>
      <hr />
    </>
  )
}

export default ScheduleDownload

const CrossHeadingContainer = styled.div`
  /* border: 1px solid red; */
  display: flex;
  /* align-items: center; */

  position: relative;
  left: -80px;
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    left: 0px;
    align-items: left;
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 0px;
  }
`

const CrossHeadingTitlesContainer = styled.div<{ titleWidth: string }>`
  /* border: 1px solid red; */
  min-width: 300px;
  display: flex;
  flex-direction: column;
  margin-left: -80px;
  width: 100%;

  @media (min-width: 768px) {
    max-width: ${props => (props.titleWidth ? props.titleWidth : "320px")};
  }
`
const CrossHeadingTitle = styled.h2`
  font-size: 50px;
  font-weight: 600;
  min-width: 320px;
  @media (max-width: 768px) {
    font-size: 30px;
    line-height: 33px;
  }
`
const HeadingContainer = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: none;
  }
`
const ButtonWrapper = styled.div`
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin: 0 auto;
  }
`

const Cross = styled.div`
  max-width: 160px;
  max-height: 160px;
  width: 160px;
  /* height: 160px; */
  @media (max-width: 768px) {
    width: 124px;
    /* height: 124px; */
  }
`

import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { RichTextContainer } from "./RichTextContainer"
import { Container } from "@material-ui/core"
import Cross from "../../assets/svg/GFW_cross.svg"
import { Button, ButtonSecondary } from "../Buttons/Buttons"
import { getUser, isLoggedIn, setUser } from "../../services/auth"
interface IIndentedTextWithLargeImage {
  data: any
}

export const HomePageSchedule: React.FC<IIndentedTextWithLargeImage> = ({ data }) => {
  const body1 = data.attrs["info-top"]
  const body2 = data.attrs["info-bottom"]
  const body3 = data.attrs["image-info"]
  //  const image = getImage(data.attrs.image)
  const btn1Text = data.attrs["top-button-text"]
  const btn1URL = data.attrs["top-button-url"]
  const btn2Text = data.attrs["bottom-button-text"]
  const btn2URL = data.attrs["bottom-button-url"]
  const btn3Text = data.attrs["button-3-text"]
  const liveStream = data.attrs["live-stream"]

  const [loggedIn, setIsLoggedIn] = useState(undefined)

  useEffect(() => {
    let mounted = true
    if (mounted) {
      setUser(getUser())
      setIsLoggedIn(isLoggedIn())
    }

    return () => {
      mounted = false
    }
  }, [])

  const image = data.attrs.image
  let jsonImage
  try {
    const rawGallery = decodeURIComponent(image)
    const jsonImageRaw = JSON.parse(rawGallery)
    jsonImage = jsonImageRaw.url
  } catch (e) {
    // console.log(e)
  }
  if(jsonImage===undefined){
  try {
    jsonImage = image.sourceUrl
  } catch (e) {
    // console.log(e)
  }
}

  const imageOnLeft = true
  return (
    <Container>
      <AboutUsWrapper imageLeft={imageOnLeft}>
        <AboutUsTextBox>
          <RichTextContainer richText={body1} />
          <ButtonSecondary to={btn1URL}>{btn1Text}</ButtonSecondary>
          <LineCrossContainer>
            <Line />
            <Cross width="30px" height="30px" /> <Line />
          </LineCrossContainer>
          <RichTextContainer richText={body2} />
          <Button to={btn2URL}>{btn2Text}</Button>
        </AboutUsTextBox>
        <AboutUsImgContainer>
          {/* <div
            style={{
              height: "100%",
              width: "auto",
              backgroundImage: `url(${image})`,
              objectFit: "cover",
            }}
          ></div> */}
         

          {/* {loggedIn ? (
                      <div dangerouslySetInnerHTML={{ __html: liveStream }} style={{width:"100%", paddingBottom:20}}></div>
                  ) : null}  */}
                 

                  <IMG src={jsonImage}></IMG>
{/*        
       {!loggedIn || loggedIn === undefined ? (
                <IMG src={jsonImage}></IMG>
                  ) : null} */}
                 
          <p>{body3}</p>
          <Button to={btn2URL}>{btn3Text}</Button>
        </AboutUsImgContainer>
      </AboutUsWrapper>
    </Container>
  )
}
interface IAlignmentProps {
  imageLeft: boolean
}
const AboutUsWrapper = styled.div<IAlignmentProps>`
  width: 100%;
  /* border: 1px solid red; */
  /* height: 600px; */
  margin: 30px auto 40px;
  display: flex;
  flex-direction: ${props => (props.imageLeft ? "row-reverse" : "row")};
  //justify-content: center;
  align-items: flex-start;
  padding-top: 60px;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: ${props => (props.imageLeft ? "column-reverse" : "column")};
    align-items: flex-end;
    height: auto;
    width: 100%;
    padding-top: 0px;
    margin: 30px auto 10px;
  }
`
const AboutUsImgContainer = styled.div`
  /* width: 50%; */
  overflow: hidden;
  /* border: 1px solid yellow; */
  padding: 120px auto;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0 auto;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: column;
    width: 100%;
    /* height: 485px; */
    margin: auto;
    margin-bottom: 20px;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    flex-direction: column;
    width: 100%;

    margin: auto;
    margin-bottom: 20px;
  }
`
const IMG = styled.img`
  object-fit: contain;
`
const AboutUsTextBox = styled.div`
  /* width: 40%; */
  min-height: wrap;
  display: flex;
  /* border: 1px solid blue; */
  flex-direction: column;
  justify-content: center;
  z-index: 2;
  padding: 40px 61px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUAgMAAADw5/WeAAAACVBMVEX6+vr4+Pjv7++npPQmAAAAKElEQVQI12NgCGBgZWBgEGBgZACzYWTo0tCoUAiJIs7gwMCCi02CXgD5agzBt66q6wAAAABJRU5ErkJggg==");
  margin: 0 60px;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
    height: auto;
    padding: 30px 20px 20px 20px;
    margin: 0;
    align-items: flex-start;
    justify-content: space-between;
  }
`

const LineCrossContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  margin: 13px auto;
  /* margin-bottom: 40px; */
`

const Line = styled.div`
  content: "";

  width: 100%;
  flex: 1;
  height: 1px;
  border: none;
  background-color: ${props => props.theme.colors.turquoise};
  @media (max-width: 1156px) {
    width: 100px;
  }
  @media (max-width: 1024px) {
    width: 140px;
  }
`

import { Container } from "@material-ui/core"
import React from "react"
import styled from "styled-components"
import { RichTextContainer } from "../LazyBlock/RichTextContainer"

interface ICrossHeading {
  data: any
}

const HowToGetThere: React.FC<ICrossHeading> = ({ data }) => {
  const subtitle = data.attrs["subtitle"]
  const title = data.attrs["title"]
  const text1 = data.attrs["text-1"]
  const text2 = data.attrs["text-2"]
  const text3 = data.attrs["text-3"]

  return (
    <Container>
      <HowToGetThereContainer>
        <CrossHeadingTitlesContainer>
          <BlackLine alt={"back"} />
          <CrossHeadingSubtitle>{subtitle} </CrossHeadingSubtitle>
          <CrossHeadingTitle>{title}</CrossHeadingTitle>
        </CrossHeadingTitlesContainer>

        <BodyContainer>
          <RichTextContainer richText={text1} />
          <RichTextContainer richText={text2} />
          <RichTextContainer richText={text3} />
        </BodyContainer>
      </HowToGetThereContainer>
    </Container>
  )
}

export default HowToGetThere

const HowToGetThereContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //align-items: center;

  margin-bottom: 50px;
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    left: 0px;
    align-items: left;
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin-top: 32px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 20px;
  }
`

const BodyContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 80px;
  width: 100%;
  padding-top: 80px;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    grid-template-columns: repeat(1, 1fr);
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 0px;
  }
`

const CrossHeadingTitlesContainer = styled.div`
  width: 33%;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
  }
`
const BlackLine = styled.div`
  height: 2px;
  background-color: black;
  width: 260px;
  margin-bottom: 53px;
`
const CrossHeadingSubtitle = styled.h6`
  z-index: 1;
  margin-bottom: 5px;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-top: 15px;
  }
`

const CrossHeadingTitle = styled.h4`
  z-index: 1;
`

import React, { useLayoutEffect, useState, FunctionComponent } from "react"
import styled from "styled-components"

// import Cursor from "./Cursor"

import SwiperCore, { Navigation, Scrollbar } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import PortfolioCarouselCard from "./PortfoliosTemplate/PortfolioCarouselCard"

// install Swiper components
SwiperCore.use([Navigation, Scrollbar])

interface ICarouselItems {
  tag: string
  studentName: string
  url: string
  desc: string
  color: string
}

interface CarouselDragProps {
  id: string
  data: Array<ICarouselItems>
  color?: string
}
const CarouselDrag: FunctionComponent<CarouselDragProps> = ({ id, data, color }) => {
  const [slides, setSlides] = useState(4)
  const [padding, setPadding] = useState(20)
  const [charLimit, setCharLimit] = React.useState(140)

  const formatText = (text: string) => {
    if (text.length < charLimit) {
      return text
    }
    return text.substr(0, charLimit) + "..."
  }
  useLayoutEffect(() => {
    const hideArrows = () => {
      if (typeof window !== "undefined") {
        const nextBtn = document.querySelector(".swiper-button-next")
        const prevBtn = document.querySelector(".swiper-button-prev")
        if (window.innerWidth >= 1200 && data.length <= 3) {
          nextBtn.style.display = "none"
          prevBtn.style.display = "none"
        } else if (window.innerWidth < 1200 && data.length > 3) {
          nextBtn.style.display = "inline-block"
          prevBtn.style.display = "inline-block"
        }
      }
    }

    function updateSize() {
      if (typeof window !== "undefined") {
        if (window.innerWidth <= 767) {
          setSlides(window.innerWidth / (320 + padding * 3))
        } else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
          setSlides(window.innerWidth / (360 + padding * 2.6))
        } else if (window.innerWidth >= 1024 && window.innerWidth < 1200) {
          setSlides(window.innerWidth / (360 + padding * 2.5))
        } else {
          setSlides(3.05)
        }
      }
    }
    window.addEventListener("resize", updateSize)
    updateSize()
    return () => {
      window.removeEventListener("resize", updateSize)
    }
  }, [])

  return (
    <Container>
      <H4FeatureProjects>{id}</H4FeatureProjects>
      <SwiperContainer>
        <Swiper
          className="swiper-container"
          slidesPerView={"auto"}
          navigation
          scrollbar={{
            hide: false,
          }}
        >
          <SwipperWrapper id={id} className="swiper-wrapper">
            {data.map((item, i) => {
              let r = Math.random().toString(36).substring(7)
              let img

              try{
                img = item.student_profile.searchImage.localFile.childImageSharp.fluid
              }catch(e){
                // console.log(e)
              }

              return (
                <SwiperSlide key={r + "-" + i} className="carousel-drag-slide">
                  <PortfolioCarouselCard
                    key={i}
                    name={item.student_profile.studentName}
                    desc={item.student_portfolio.aboutMeHeading}
                    slug={item.slug}
                    tags={item.student_profile.specialism}
                    color={color}
                    img={img}
                  ></PortfolioCarouselCard>
                </SwiperSlide>
              )
            })}
          </SwipperWrapper>
        </Swiper>
      </SwiperContainer>
    </Container>
  )
}

export default CarouselDrag

const FullWidth = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Container = styled.section`
  padding: 25px 20px 50px;
  overflow: hidden;

  @media (min-width: 768px) {
    padding: 50px 20px;
  }
  @media (min-width: 1024px) {
    padding: 50px 3%;
  }

  .swiper-container {
    overflow: visible;
    transition: transform 0.8s ease;
    width: 100%;
    margin-right: auto;
    transition: transform 0.4s ease;
    @media (min-width: 1024px) {
      width: 100%;
    }
  }
  .carousel-drag-slide {
    /* padding: 35px 37px 37px;
    background: white;
    width: 320px !important;
    min-width: 320px !important;
    height: auto;
    transition: transform 1s ease;
    margin-right: 20px;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05); */
    margin: auto 10px;

    @media (min-width: 768px) {
      padding: 0px;
      width: 315px !important;
      min-width: 315px !important;
      /* display: flex;
      flex-direction: column;
      align-items: flex-end; */
    }
    &:hover .image__container {
      box-shadow: 0 0 26px 0 rgba(0, 0, 0, 0.2);
    }
    &:hover .text__container {
      box-shadow: 0 0 26px 0 rgba(0, 0, 0, 0.2);
    }
  }

  .swiper-scrollbar {
    position: absolute;
    height: 1px;
    width: calc(100vw - 140px);
    background: ${props => props.theme.colors.turquoise};
    left: 100px;
    bottom: -40px;
    @media screen and (min-width: 1024px) {
      width: calc(100vw - 6% - 100px);
    }
    @media screen and (min-width: 1200px) {
      width: 92%;
    }
  }
  .swiper-scrollbar-drag {
    height: 3px;
    position: absolute;
    top: -1px;
    background: ${props => props.theme.colors.turquoise};
  }

  .swiper-button-next {
    margin-left: 18px;
    left: 15px;
  }

  .swiper-button-prev {
    transform: rotate(-180deg);
    left: 0;
  }
  .swiper-button-next::after,
  .swiper-button-prev::after {
    opacity: 0;
  }
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: auto;
    bottom: -50px;
    width: 4.9rem;
    height: 1rem;
    margin: 0;
  }
  .swiper-button-next {
    left: 50px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ny44NCAxMS4yNSI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiMwZGIwZTc7ZmlsbC1ydWxlOmV2ZW5vZGQ7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGlkPSJDb21iaW5lZC1TaGFwZSIgY2xhc3M9ImNscy0xIiBkPSJNNDUuMzgsNiw0MS4xNiwxLjg0bDEtMSw1LjcsNS42Mi01LjcsNS42Mi0xLTFMNDUuMzgsN0gwVjZaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC0wLjg4KSIvPjwvc3ZnPg==);
    background-repeat: no-repeat;
    /* background-color: ${props => props.theme.colors.turquoise}; */
    background-size: 100% auto;
    background-position: center;
    width: 45px;
    height: 21px;
  }
  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    /* background: url(../assets/svg/long-arrow-right.svg); */
    background-repeat: no-repeat;
    background-size: 100% auto;

    background-position: center;
  }
  .swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
  }
`

const SwiperContainer = styled.div`
  cursor: grab;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  position: relative;
`
const SwipperWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  box-sizing: content-box;
`

const Tag = styled.span`
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.76px;
  line-height: 14px;
  padding: 1px 10px 3px 10px;
  background-color: ${props => (props.color ? props.color : props.theme.colors.purple)};
  color: white;
  border-radius: 20px;

  @media (max-width: 768px) {
    font-size: 10px;

    letter-spacing: 0.64px;
    line-height: 10px;
  }
`

const Student = styled.h6`
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  margin-bottom: 32px;
  margin-top: 10px;
  @media (max-width: 768px) {
    font-size: 16px;

    line-height: 21px;
  }
`

const Desc = styled.p`
  font-size: 18px;
  letter-spacing: 0;
  line-height: 26px;
  color: #4a4a4a;
  font-weight: 400;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 24px;
  }
`
interface ILinkWrapper {
  noMarginTop?: boolean
}
const InlineLinkWrapper = styled.div<ILinkWrapper>`
  margin-top: 22px;
  display: flex;

  margin-bottom: 30px;
  @media (min-width: 1024px) {
    margin-top: 47px;
  }
  @media (min-width: 1200px) {
    margin-top: ${props => (props.noMarginTop ? "10px" : "47px")};
  }
`

const H4FeatureProjects = styled.h4`
  line-height: 42px;
  margin-bottom: 14px;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`
const CarouselImgContainer = styled.div`
  width: 315px;
  height: 325px;
  border-top: 4px solid ${props => (props.color ? props.color : props.theme.colors.secondary)};
  & > .gatsby-image-wrapper {
    z-index: -1;
  }
  /* &:hover {
    box-shadow: 0 0 26px 0 rgba(0, 0, 0, 0.2);
  } */
`
const CarouselTextContainer = styled.div`
  padding: 35px 37px 37px;
  background: white;
  height: 185px;
  width: 295px;
  background-color: #ffffff;
  box-shadow: 0 0 5px 0 rgba(123, 123, 123, 0.2);
  transition: transform 1s ease;
  margin-top: -100px;
  z-index: 10;
  @media (max-width: 768px) {
    padding: 19px 23px;
  }
`

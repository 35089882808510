import { Container } from "@material-ui/core"
import React from "react"
import styled from "styled-components"
import { Button, ButtonSecondary } from "../Buttons/Buttons"

import { RichTextContainer } from "../LazyBlock/RichTextContainer"

interface ICrossHeading {
  data: any
}

const MapEvent: React.FC<ICrossHeading> = ({ data }) => {
  const subtitle = data.attrs["sub-heading"]
  const title = data.attrs["heading"]
  const text1 = data.attrs["text-box"]
  const coordinates = data.attrs["coordinates"]
  const ctaUrl1 = data.attrs["cta-url-1"]
  const ctaUrl2 = data.attrs["cta-url-2"]
  const ctaText1 = data.attrs["cta-text-1"]
  const ctaText2 = data.attrs["cta-text-2"]

  const LngLat = coordinates

  const MapURL =
    "https://www.google.com/maps/embed/v1/place?q=" + LngLat + "&key=AIzaSyCSAPHCcQKfCOW1lU7PA6mwFp9PiH7ujeI"

  return (
    <Container>
      <BlackLine />
      <CrossHeadingContainer>
        <LeftColumn>
          <CrossHeadingSubtitle>{subtitle} </CrossHeadingSubtitle>
          <CrossHeadingTitle>{title}</CrossHeadingTitle>
          <RichTextContainer richText={text1} />
          {ctaText1 && (
            <Button to={ctaUrl1} size={1} customwidth={"60%"}>
              {ctaText1}
            </Button>
          )}
          <Gap></Gap>
          {ctaText2 && (
            <ButtonSecondary to={ctaUrl2} size={1} customwidth={"60%"} secondary>
              {ctaText2}
            </ButtonSecondary>
          )}
        </LeftColumn>

        <MapContainer>
          <iframe width="100%" height="100%" loading="lazy" allowFullScreen frameBorder="0" src={MapURL}></iframe>
        </MapContainer>
      </CrossHeadingContainer>
    </Container>
  )
}

export default MapEvent

const CrossHeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  //align-items: center;

  margin-bottom: 50px;
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    left: 0px;
    align-items: left;
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin-top: 32px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 20px;
  }
`

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;

  width: 40%;

  padding: 0px;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
    padding: 10px 0px;
  }
`

const MapContainer = styled.div`
  display: flex;
  grid-column-gap: 30px;
  width: 60%;
  height: 450px;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 0px;
  }
`

const CrossHeadingTitlesContainer = styled.div``

const Gap = styled.div`
  height: 17px;
`
const CrossHeadingSubtitle = styled.h6`
  z-index: 1;
  margin-bottom: 5px;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-top: 15px;
  }
`

const CrossHeadingTitle = styled.h4`
  z-index: 1;
`

const BlackLine = styled.div`
  height: 2px;
  background-color: black;
  width: 260px;
  margin-bottom: 53px;
`

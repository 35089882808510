import { Container } from "@material-ui/core"
import React from "react"
import styled from "styled-components"
import CrossIcon from "../../assets/svg/GFW_cross.svg"
import { RichTextContainer } from "../LazyBlock/RichTextContainer"

interface ICrossHeading {
  data: any
}

const CrossHeadingRichGrid: React.FC<ICrossHeading> = ({ data }) => {
  const subtitle = data.attrs["sub-title"]
  const title = data.attrs["main-title"]
  const text1 = data.attrs["contact-box-1"]
  const text2 = data.attrs["contact-box-2"]
  const text3 = data.attrs["contact-box-3"]
  const text4 = data.attrs["contact-box-4"]
  return (
    <Container>
      <CrossHeadingContainer>
        <LeftColumn>
          <Cross />
          <CrossHeadingTitlesContainer>
            <CrossHeadingSubtitle>{subtitle} </CrossHeadingSubtitle>
            <CrossHeadingTitle>{title}</CrossHeadingTitle>
          </CrossHeadingTitlesContainer>
        </LeftColumn>

        <BodyContainer>
          <BodyTop>
            <RichTextContainer richText={text1} />
            <RichTextContainer richText={text2} />
          </BodyTop>

          <BodyBottom>
            <RichTextContainer richText={text3} />
            <RichTextContainer richText={text4} />
          </BodyBottom>
        </BodyContainer>
      </CrossHeadingContainer>
    </Container>
  )
}

export default CrossHeadingRichGrid

const CrossHeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  //align-items: center;

  margin-bottom: 50px;
  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    left: 0px;
    align-items: left;
  }
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    margin-top: 32px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-bottom: 20px;
  }
`
const Cross = styled(CrossIcon)`
  max-width: 230px;
  max-height: 230px;

  @media (max-width: 768px) {
    width: 120px;
    height: 120px;
  }
`
const LeftColumn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 40%;
  margin-top: 60px;
  padding: 0px 20px;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
    padding: 10px 0px;
    justify-content: flex-start;
    align-items: center;
  }
`

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
  }
`
const BodyTop = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;
  width: 100%;
  padding-top: 80px;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 0px;
  }
`

const BodyBottom = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const CrossHeadingTitlesContainer = styled.div``

const CrossHeadingSubtitle = styled.h6`
  z-index: 1;
  margin-bottom: 5px;
  margin-left: -50px;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin-top: 15px;
  }
`

const CrossHeadingTitle = styled.h4`
  text-transform: uppercase;
  z-index: 1;
  margin-left: -50px;
`

import React from "react"
import { ButtonSecondary } from "../Buttons/Buttons"
import { RichTextContainer } from "./RichTextContainer"
import { SizeType } from "../Buttons/Buttons"

interface UniversityCourseBlockI {
  data: any
}

export const UniversityCourseBlock: React.FC<UniversityCourseBlockI> = ({ data }) => {
  const name = data.attrs["course-name"]
  const description = data.attrs["course-description"]
  const link = data.attrs["course-link"]
  return (
    <div style={{ paddingTop: 65 }}>
      <h5 style={{ fontSize: "1.6rem", marginBottom: "1rem" }}>{name}</h5>
      <RichTextContainer richText={description} />
      <div style={{ maxWidth: 300, marginTop: 30 }}>
        <ButtonSecondary to={link} target={"__blank"} size={SizeType.large}>
          Find out more
        </ButtonSecondary>
      </div>
    </div>
  )
}

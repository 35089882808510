import React, { useState } from "react"
import styled, { useTheme } from "styled-components"
import { RichTextContainer } from "./RichTextContainer"

import { Swiper, SwiperSlide } from "swiper/react"
import { PartnerGalleryItem } from "./PartnerGalleryItem"
import { makeStyles } from "@material-ui/core/styles"
import { GalleryCaroselPopup } from "./GalleryCaroselPopup"

interface PartnershipGallery {
  data: any
}
const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "40%",
    minWidth: "300px",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: "20px",
  },
}))

export const PartnershipGallery: React.FC<PartnershipGallery> = ({ data }) => {
  const headline = data.attrs.headline
  const body = data.attrs.body
  const gallery = data.attrs.gallery

  const theme = useTheme()
  const [slides, setSlides] = useState(3)
  let jsonGallery
  let pairs
  const rawGallery = decodeURIComponent(gallery)
  try{
  jsonGallery = JSON.parse(rawGallery)
  pairs = jsonGallery.reduce(function (result, value, index, array) {
    if (index % 2 === 0) result.push(array.slice(index, index + 2))
    return result
  }, [])
  }catch(e){
    // console.log(e)
  }
  
  const [modalOpen, setModalOpen] = useState(false)
  const [modalStyle] = React.useState(getModalStyle)
  const [cardNumber, setCardNumber] = React.useState(0)

  const handleOpen = () => {
    setModalOpen(true)
  }

  const handleClose = () => {
    setModalOpen(false)
  }

  function rand() {
    return Math.round(Math.random() * 20) - 10
  }
  function getModalStyle() {
    const top = 50
    const left = 50

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    }
  }
  const classes = useStyles()

  return (
    <MainContainer>
      <AboutUsWrapper>
        <AboutUsTextBox>
          <Subtitle>{headline}</Subtitle>
          <RichTextContainer richText={body} />
        </AboutUsTextBox>
        <GalleryCaroselPopup
          imageData={jsonGallery}
          setModalOpen={setModalOpen}
          modalOpen={modalOpen}
          cardNumber={cardNumber}
          setCardNumber={setCardNumber}
        />

        <VideoContainer>
          <StyledSwiper
            spaceBetween={10}
            slidesPerView={3}
            navigation
            scrollbar={{
              hide: false,
            }}
          >
            {pairs ? pairs.map((v, i) => {
              return (
                <SwiperSlide key={"gallery-" + i} className="carousel-drag-slide">
                  <StyledCardContainer>
                    <PartnerGalleryItem
                      onClick={handleOpen}
                      imageData={v[0]}
                      index={i * 2}
                      setModalOpen={setModalOpen}
                      setCardNumber={setCardNumber}
                    />
                    <PartnerGalleryItem
                      imageData={v[1]}
                      index={i * 2 + 1}
                      setModalOpen={setModalOpen}
                      setCardNumber={setCardNumber}
                    />
                  </StyledCardContainer>
                </SwiperSlide>
              )
            }):(null)}
          </StyledSwiper>
        </VideoContainer>
      </AboutUsWrapper>
    </MainContainer>
  )
}

const StyledCard = styled.div`
  border: 1px solid red;
  height: 280px;
  width: 280px;
`

const StyledSwiper = styled(Swiper)`
  /* border: 1px solid red; */
  height: 600px;
  overflow: visible;
  transition: transform 0.8s ease;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    height: 450px;
  }
`
const ImageContainer = styled.div`
  //height:100%;

  width: 100%;
  overflow: hidden;
  display: flex;
  background-position: center;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
  }
`

const StyledCardContainer = styled.div`
  /* border: 1px solid red; */
  /* height: 100%; */
  /* overflow: visible;  */
  display: flex;
  flex-direction: column;
  height: 580px;
`

const MainContainer = styled.div`
  padding-top: 50px;
  margin: 30px auto;
  overflow: visible;
  /* border: 1px solid red; */
`

const AboutUsWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 99px;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: column-reverse;
    padding: 20px 0;
    height: 700px;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    height: 700px;
  }

  .carousel-drag-slide {
    height: 600px;
  }

  .swiper-scrollbar {
    z-index: 100;
    position: absolute;
    height: 1px;
    width: calc(100vw - 140px);
    background: ${props => props.theme.colors.turquoise};
    left: 100px;
    bottom: -10px;
    @media screen and (min-width: 1024px) {
      width: calc(100vw - 6% - 100px);
    }
    @media screen and (min-width: 1200px) {
      width: 92%;
    }
  }
  .swiper-scrollbar-drag {
    height: 3px;
    position: absolute;
    top: 0px;
    background: ${props => props.theme.colors.turquoise};
  }

  .swiper-button-next {
    margin-left: 18px;
    left: 15px;
    bottom: -10px;
  }

  .swiper-button-prev {
    transform: rotate(-180deg);
    left: 0;
  }
  .swiper-button-next::after,
  .swiper-button-prev::after {
    opacity: 0;
  }
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: auto;
    bottom: -20px;
    width: 4.9rem;
    height: 1rem;
    margin: 0;
  }
  .swiper-button-next {
    left: 50px;
  }

  .swiper-button-next,
  .swiper-button-prev {
    background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ny44NCAxMS4yNSI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiMwZGIwZTc7ZmlsbC1ydWxlOmV2ZW5vZGQ7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGlkPSJDb21iaW5lZC1TaGFwZSIgY2xhc3M9ImNscy0xIiBkPSJNNDUuMzgsNiw0MS4xNiwxLjg0bDEtMSw1LjcsNS42Mi01LjcsNS42Mi0xLTFMNDUuMzgsN0gwVjZaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC0wLjg4KSIvPjwvc3ZnPg==);
    background-repeat: no-repeat;
    /* background-color: ${props => props.theme.colors.turquoise}; */
    background-size: 100% auto;
    background-position: center;
    width: 45px;
    height: 21px;
  }
  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    /* background: url(../assets/svg/long-arrow-right.svg); */
    background-repeat: no-repeat;
    background-size: 100% auto;

    background-position: center;
  }
  .swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
  }
`
const VideoContainer = styled.div`
  width: 65%;

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: column-reverse;
    width: 100%;
  }
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    margin: 30px auto;
    flex-direction: column-reverse;
    height: auto;
  }
`

const AboutUsTextBox = styled.div`
  width: 35%;
  height: 600px;
  z-index: 105;
  background-color: white;

  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 2;
  padding: 0 30px;
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    width: 100%;
    padding: 60px 0px 20px 0px;

    align-items: flex-start;
  }
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    max-width: 560px;
  }
`

const Subtitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 30px;
  text-transform: uppercase;
  margin-bottom: 15px;
  text-align: left;
`

import styled from "styled-components"
import React from "react"

interface ThumbnailGalleryItemI {
  imageData: any
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  index: number
  setCardNumber: any
}

export const ThumbnailGalleryItem: React.FC<ThumbnailGalleryItemI> = ({
  imageData,
  setModalOpen,
  index,
  setCardNumber,
}) => {
  const HandleOnClick = () => {
    setModalOpen(true)
    setCardNumber(index)
  }
  return (
    <ImageContainer onClick={HandleOnClick} url={imageData.url}>
      {/* <StyledImg></StyledImg> */}
    </ImageContainer>
  )
}

interface ImageContianerI {
  url: string
}

const ImageContainer = styled.div<ImageContianerI>`
  height: 300px;
  width: 100%;
  overflow: hidden;
  display: flex;
  background-position: center;
  background-image: url(${props => props.url});
  background-size: cover;

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    height: 200px;
  }
`
const StyledImg = styled.img`
  flex: 1;
`

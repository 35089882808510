import React from "react"
import styled from "styled-components"
import { RichTextContainer } from "./RichTextContainer"
import MainGrid from "../../style/MainGrid"

interface ISmallTextWithGallery {
  data: any
}

export const SmallTextWithGallery: React.FC<ISmallTextWithGallery> = ({ data }) => {
  const subtitle = data.attrs.subtitle
  const body = data.attrs.body
  const images = data.attrs.gallery

  return (
    <MainGrid>
      <AboutUsWrapper>
        <AboutUsTextBox>
          <Subtitle>{subtitle}</Subtitle>
          <RichTextContainer richText={body} />
        </AboutUsTextBox>
        <AboutUsImgContainer>{/* <GalleryCarousel id={'Double Gall Id'} data={images}/> */}</AboutUsImgContainer>
      </AboutUsWrapper>
    </MainGrid>
  )
}

const AboutUsWrapper = styled.div`
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-end;

    width: 100%;
  }
`
const AboutUsImgContainer = styled.div`
  width: 50%;
  height: 500px;
  padding: 120px auto;
  z-index: 2;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    height: 485px;

    margin: auto;
  }
`

const AboutUsTextBox = styled.div`
  width: 50%;
  min-height: wrap;

  display: flex;

  flex-direction: column;
  justify-content: center;
  z-index: 2;
  padding: 30px;
  margin: 0;
  @media (max-width: 768px) {
    width: 100%;
    height: 200px;
    padding: 60px 20px 20px 0px;
    margin: 0;
    align-items: flex-start;
    justify-content: space-between;
  }
`

const Subtitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 30px;
  text-transform: uppercase;
  margin-bottom: 15px;
  text-align: left;
`
